@import "./_mantine.scss";
.sectionHeaderTimesAndAvailability {
  font-size: var(--mantine-font-size-lg);
  line-height: 24px;
  font-weight: 700;
  color: var(--mantine-color-blue-8);
  margin-top: var(--mantine-spacing-md);
  margin-bottom: var(--mantine-spacing-xs);
  @media (min-width: $mantine-breakpoint-sm) {
    margin-left: var(--mantine-spacing-sm);
  }
}
.detailsItemDateRange {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  margin-bottom: 4;
  p {
    font-size: var(--mantine-font-size-md);
    margin: 0;
    margin-left: var(--mantine-spacing-xs);
    color: var(--mantine-color-blue-8);
  }
  svg: {
    color: var(--mantine-color-blue-5);
    text-align: center;
  }
  @media (min-width: $mantine-breakpoint-sm) {
    margin-bottom: var(--mantine-spacing-xs);
  }
}

.subsOffDates {
  margin-top: var(--mantine-spacing-lg);
  margin-bottom: var(--mantine-spacing-xs);
  margin-left: var(--mantine-spacing-sm);
  @media (max-width: $mantine-breakpoint-sm) {
    margin-left: 0;
  }
}
.someDatesSoldOut {
  background-color: var(--mantine-color-red-1);
  border-radius: 4px;
  color: var(--mantine-color-red-8);
  text-transform: none;
  margin-top: var(--mantine-spacing-md);
}
.notificationIcon {
  background-color: var(--mantine-color-blue-1) !important;
  margin-right: var(--mantine-spacing-xs);
  svg {
    background: var(--mantine-color-blue-1);
  }
}
.notificationRoot {
  background: var(--mantine-color-blue-1);
  box-shadow: none;
  margin: var(--mantine-spacing-sm) auto var(--mantine-spacing-sm);
  @media (max-width: $mantine-breakpoint-sm) {
    padding-left: var(--mantine-spacing-md);
    padding-right: var(--mantine-spacing-md);
    margin-inline: calc(var(--mantine-spacing-md) * -1);
  }
}
.notificationTitle {
  color: var(--mantine-color-blue-8);
  font-weight: 600;
  font-size: var(--mantine-font-size-sm);
  line-height: 16px;
}
.anchorTag {
  scroll-margin-top: 20vh;
}
.card {
  box-shadow: 0px 4px 24px rgba(11, 23, 78, 0.1);
  border-radius: 12px 12px 24px 24px;
  margin-top: var(--mantine-spacing-md);
  max-width: 400px;
  width: 100%;
  @media (max-width: $mantine-breakpoint-sm) {
    width: 100%;
    margin: var(--mantine-spacing-md) auto;
  }
}

.bookingSelectInput {
  border: 1px solid var(--mantine-color-gray-4);
  border-radius: 8px;
  margin-bottom: var(--mantine-spacing-sm);
  color: var(--mantine-color-gray-5);
  padding: 0 24px;

  @media (max-width: $mantine-breakpoint-sm) {
    padding: 0 12px;
  }

  &.bookingSelectInputError {
    border: 1px solid var(--mantine-color-red-6);

    & > span {
      color: var(--mantine-color-red-6);
    }
    & + div > svg {
      fill: var(--mantine-color-red-6);
    }
  }
}

.bookingSelectSection svg path {
  border: 2px solid var(--mantine-color-blue-8);
}

.dotted {
  border-bottom: 1px dotted var(--mantine-color-gray-4);
  height: 1px;
  width: 100%;
  margin: 12px 0;

  &:last-of-type {
    display: none;
  }
}

.classSelect {
  &:hover {
    background-color: var(--mantine-color-blue-1);
  }
}

.classSelectPlaceholder {
  color: var(--mantine-color-gray-5);
  font-weight: 600;
  font-size: var(--mantine-font-size-md);
}

.dropdown {
  box-shadow: 0px 4px 54px 0px #15254e29;
  overflow-y: scroll;
  max-height: 45vh;
}

.modalContent {
  background-color: #f7f7f7;

  .classesOptions {
    background-color: white;
    padding: 12px;
    border-radius: 16px;
    box-shadow: 0px 0px 80px 0px #00000008;
  }
}

.modalTitle {
  font-size: 1.375rem;
  font-weight: 700;
  color: var(--mantine-color-blue-8);
}

.modalHeader {
  background-color: #f7f7f7;
  padding: 20px 20px 16px 20px;
}

.modalBody {
  padding: 0 20px 20px;
}

.daysOfWeekDisplayLine {
  margin-bottom: var(--mantine-spacing-xl);
}
