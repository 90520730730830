@import "./_mantine.scss";
.modalContent {
  color: var(--mantine-color-blue-8);
}

.submitButton {
  background-color: var(--mantine-color-pink-6);
  font-size: 18px;
  font-weight: bold;

  &:hover {
    background-color: var(--mantine-color-pink-6);
  }
}
