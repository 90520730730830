@import "./_mantine.scss";
.button {
  color: var(--mantine-color-blue-8);
  background-color: var(--mantine-color-lime-5);
  font-weight: 700;
  padding: 0 var(--mantine-spacing-xs);
  min-width: 120px;
  &:hover {
    color: white;
    background-color: var(--mantine-color-blue-8) !important;
  }
  span {
    margin-right: 2px;
  }
}

.subsPriceButton {
  width: 100%;
}
